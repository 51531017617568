import { css } from "@emotion/react";
import { Link, RoundIcon } from "@whitespace/components";
import PageContent from "@whitespace/gatsby-theme-wordpress-basic/src/components/PageContent";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./Shortcuts.module.css";

Shortcuts.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

const iconBackgroundColor = (name) =>
  ({
    calendar: "#004F68",
    bed: "#58A9DE",
    backpack: "#EEA15D",
    compass: "#83BCC4",
    beach: "#E7E2D8",
    food: "#E7E2D8",
    cart: "#32434B",
  }[name]);

export default function Shortcuts({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const {
    contentNode: { title, content: contentHTML },
  } = usePageContext();
  const menu = useMenu("SHORTCUTS");
  const items = menu?.items;

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <div className={styles.wrapper}>
        <PageContent input={contentHTML}>
          {({ preamble }) => (
            <div className={styles.content}>
              <div className={styles.content}>
                <h1 className={styles.title}>{title}</h1>
              </div>

              {preamble && (
                <div className={clsx(styles.description)}>{preamble}</div>
              )}
            </div>
          )}
        </PageContent>

        {items && items.length > 0 && (
          <nav className={styles.nav} aria-label="Genvägar">
            <ul className={styles.list}>
              {items.map((item, index) => (
                <li className={styles.item} key={index}>
                  <Link className={styles.link} to={item.url}>
                    {item.icon && (
                      <RoundIcon
                        name={item.icon.name}
                        className={styles.icon}
                        css={css({
                          "--round-icon-background-color": iconBackgroundColor(
                            item.icon.name,
                          ),
                        })}
                      />
                    )}
                    <span className={styles.label}>{item.label}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
}
